import React, { Fragment } from 'react';
import { translate } from '../Services/translation';

const FilterTags = (({facetFilters, searchFacetChange}) => {
    const selectedFacetGroup = facetFilters.filter(filter => filter.selectedOptions.length>0);
    return ( selectedFacetGroup.length === 0 ? null :
        <div className="selected-filter">
            <span> {translate('search.yourfilter')} : </span>
            {selectedFacetGroup && selectedFacetGroup.map((group, index) =>
                <span className="selected-filter__tag" onClick={event => searchFacetChange(group)} key={`filterTag-${index}`}> {group.label} </span>
            )}
        </div>
)})

export default FilterTags;
import { post, put } from '../Services/http';
import { catchError } from './Error.action';
import { translate } from '../Services/translation';

export const PRODUCT_ADD_TO_BACK_IN_STOCK = 'PRODUCT_ADD_TO_BACK_IN_STOCK';

export const addToBackInStock = (variantId) => (dispatch) => {
	dispatch({
		type: PRODUCT_ADD_TO_BACK_IN_STOCK,
		payload: {
			loading: true,
		}
	});

	return post('/api/product/add-to-back-in-stock', {variantId: variantId})
		.then((response) => response.json())
		.then(data => dispatch(backInStockSuccess(data)))
		.catch(ex => backInStockError(ex))
}

const backInStockSuccess = data => ({
		type: PRODUCT_ADD_TO_BACK_IN_STOCK,
		payload: {
			loading: false,
			...data
		}
})

const backInStockError = (ex) => (dispatch) => {	
	dispatch({
		type: PRODUCT_ADD_TO_BACK_IN_STOCK,
		payload: {
			loading: false,
			...ex
		}
	});
	dispatch(catchError(ex, error => submitError(error)));
}
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import FacetedSearchCompact from '../Components/FacetedSearchCompact';
import {AccordionPanel, Accordion} from './Accordion.container';
import { query, searchFacetChange, toggleVisibleDropdownMenu } from '../Actions/FacetedSearch.action';
import SubNav from '../Components/SubNavCompact';
import SortCriteriaCompact from '../Components/SortCriteriaCompact';
import FilterTag from '../Components/FilterTags';

class FacetedSearchCompactContainer extends Component {
    componentDidMount() {
        this.props.query(window.location.search.substr(1) || '', false);
    }

    render() {
        const hidden = this.props.subNavigation.length === 0 && this.props.facetFilters.length === 0 && this.props.sortCriteria.length === 0;
        return ( hidden ? null :
            <Fragment>
                <FilterTag {...this.props}/>
                <Accordion className='compact-filter hide-for-large'>
                    {this.props.subNavigation.length > 0 &&
                    <AccordionPanel header='faceted.categories'>
                        <SubNav {...this.props}/>
                    </AccordionPanel>
                    }
                    {this.props.facetFilters.length > 0 &&
                    <AccordionPanel header='faceted.filter'>
                        <FacetedSearchCompact {...this.props} />
                    </AccordionPanel>
                    }
                    {this.props.sortCriteria.length > 0 &&
                    <AccordionPanel header='faceted.sort-criteria'>
                        <SortCriteriaCompact {...this.props}/>
                    </AccordionPanel>
                    }
                </Accordion>
                {this.props.navigationTheme==='category' && (
                    <div className='compact-filter category-theme show-for-large'>
                        <FacetedSearchCompact {...this.props}/>
                    </div>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = ({facetedSearch:{subNavigation, sortCriteria, facetFilters, visibleDropdownMenu, navigationTheme}}) => {
    return {
        subNavigation,
        sortCriteria,
        facetFilters,
        visibleDropdownMenu,
        navigationTheme,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        query: (q) => dispatch(query(q)),
        searchFacetChange: (group, item, forceSubmit) => dispatch(searchFacetChange(group, item, forceSubmit)),
        toggleVisibleDropdownMenu: (group) => dispatch(toggleVisibleDropdownMenu(group)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FacetedSearchCompactContainer);
export const ValidateSSN = (ssn) => {
    const currentCountry = window.__litium.constants.countries.filter(country => country.selected)[0];
    switch(currentCountry.value) {
        case 'SE':
            return ValidateSSNSE(ssn);
        case 'NO':
            return ValidateSSNNO(ssn);
        case 'DK':
            return ValidateSSNDK(ssn);
        case 'FI':
            return ValidateSSNFI(ssn);
    }
}

const ValidateSSNFI = (ssn) => {
    if (!ssn) { return false; }

    if (ssn.indexOf('-') === -1) {
        if (ssn.length === 10) {
            ssn = ssn.slice(0, 6) + "-" + ssn.slice(6);
        } else if (ssn.length === 12) {
            ssn = ssn.slice(2, 8) + "-" + ssn.slice(8);
        }
    }
    ssn = ssn.replace("-", "");

    const controlChars =  "0123456789ABCDEFHJKLMNPRSTUVWXY";
    const controlChar = ssn.substr(ssn.length - 1);
    const forModulo = ssn.substr(0, ssn.length - 1);
    const modulo = forModulo % 31;

    return controlChar == controlChars.charAt(modulo);
}

const ValidateSSNDK = (ssn) => {
    if (!ssn) { return false; }

    const regex = "/([0-9]{6})(-)?([0-9]{4})/";
    return ssn.match(regex) !== null;
}
const ValidateSSNNO = (ssn) => {    
    if (!ssn) { return false; }

	ssn = ssn.toString();
    if(!ssn || ssn.length !== 11){
        return false;
    }

    var _sum = function(ssn, factors){
        var sum = 0;
        for(let i = 0, l = factors.length; i < l; ++i){
            sum += parseInt(ssn.charAt(i),10) * factors[i];
        }
        return sum;
    };

    var checksum1 = 11 - (_sum(ssn, [3, 7, 6, 1, 8, 9, 4, 5, 2]) % 11);
    if (checksum1 === 11) checksum1 = 0;
    var checksum2 = 11 - (_sum(ssn, [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]) % 11);
    if (checksum2 === 11) checksum2 = 0;
    return checksum1 === parseInt(ssn.charAt(9), 10) 
            && checksum2 === parseInt(ssn.charAt(10), 10);
}

const ValidateSSNSE = (ssn) => {
    // Check valid length & form
    if (!ssn) { return false; }

    if (ssn.indexOf('-') === -1) {
        if (ssn.length === 10) {
            ssn = ssn.slice(0, 6) + "-" + ssn.slice(6);
        } else {
            ssn = ssn.slice(0, 8) + "-" + ssn.slice(8);
        }
    }
    if (!ssn.match(/^(\d{2})(\d{2})(\d{2})\-(\d{4})|(\d{4})(\d{2})(\d{2})\-(\d{4})$/)) { return false };

    // Clean ssn
    ssn = ssn.replace('-', '');
    if (ssn.length === 12) {
        ssn = ssn.substring(2);
    }

    // Declare variables
    var d = new Date(((!!RegExp.$1) ? RegExp.$1 : RegExp.$5), (((!!RegExp.$2) ? RegExp.$2 : RegExp.$6)-1), ((!!RegExp.$3) ? RegExp.$3 : RegExp.$7)),
            sum = 0,
            numdigits = ssn.length,
            parity = numdigits % 2,
            i,
            digit;

    // Check valid date
    if (Object.prototype.toString.call(d) !== "[object Date]" || isNaN(d.getTime())) return false;

    // Check luhn algorithm
    for (i = 0; i < numdigits; i = i + 1) {
        digit = parseInt(ssn.charAt(i), 10);
        if (i % 2 === parity) { digit *= 2; }
        if (digit > 9) { digit -= 9; }
        sum += digit;
    }
    return (sum % 10) === 0;
}
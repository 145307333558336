import { catchError } from './Error.action';

export const CHECKED_COOKIE = 'CHECKED_COOKIE';
export const ACCEPT_COOKIES = 'ACCEPT_COOKIES';
export const SHOW_MORE = 'SHOW_MORE';

function setCookie(cname, cvalue, exdays) {
	var d = new Date();
	d.setTime(d.getTime() + (exdays*24*60*60*1000));
	var expires = "expires="+ d.toUTCString();
	document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
	var name = cname + "=";
	var decodedCookie = decodeURIComponent(document.cookie);
	var ca = decodedCookie.split(';');
	for(var i = 0; i <ca.length; i++) {
		var c = ca[i];
		while (c.charAt(0) == ' ') {
			c = c.substring(1);
		}
		if (c.indexOf(name) == 0) {
			return c.substring(name.length, c.length);
		}
	}
	return "";
}

export const checkCookie = () => (dispatch, getState) => {
	let cookiesAccepted = getCookie('accepted-cookies');

	if (cookiesAccepted.length) {
		dispatch(checked(true));
		return;
	}
	dispatch(checked(false));
	return;
}

export const acceptCookies = () => (dispatch, getState) => {
	setCookie('accepted-cookies', 'true', 180);
	dispatch(acceptedCookies(true));
}

export const checked = accepted => ({
	type: CHECKED_COOKIE,
	payload: {
		acceptedCookies: accepted,
		checkedForCookie: true
	},
})

export const acceptedCookies = accepted => ({
	type: ACCEPT_COOKIES,
	payload: {
		acceptedCookies: accepted
	},
})

export const showMore = () => (dispatch, getState) => {
    dispatch({
        type: SHOW_MORE,
        payload: {
            showMore: !getState().cookiesCTA.showMore
        }
    });
}

import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import Navigation from '../Components/Navigation';
import { load, showMenu, hideMenu } from '../Actions/Navigation.action';
import ReactDOM from 'react-dom';

class NavigationContainer extends Component {
    constructor(props) {
        super(props);
        this.thisRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }
    componentDidMount() {
        this.props.load();
        document.addEventListener('click', this.handleClickOutside, true);
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside, true);
    }


    toggleMenu() {
        if (this.props.visible)
            this.props.hideMenu();
        else
            this.props.showMenu();
    }

    handleClickOutside(event) {
        if (!this.thisRef.current.contains(event.target)) {
            this.props.hideMenu();
        }
    }

    render() {
        return (
            <div className="navbar__container" ref={this.thisRef}>
                <a className="navbar__link--block navbar__icon--menu navbar__icon" onClick={() => this.toggleMenu()}></a>
                <div className={(this.props.visible ? "navbar__menu--show" : "") + " navbar__menu"}>
                    <div className="navbar__close-container">
                        <div className="navbar__close-icon text--right" onClick={() => this.toggleMenu()}></div>
                    </div>
                    <ul className="navbar__item-container">
                        {this.props.links.map((link, i) => !link.name ? null : (
                            <Navigation links={link.links} contentLink={link} key={i}/>
                        ))}
                    </ul>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        links: state.navigation.contentLinks,
        visible: state.navigation.visible,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        load: () => dispatch(load()),
        showMenu: () => dispatch(showMenu()),
        hideMenu: () => dispatch(hideMenu()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavigationContainer);
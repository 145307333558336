import { 
    PRODUCT_ADD_TO_BACK_IN_STOCK,
} from '../Actions/InStock.action';
import { error as errorReducer } from './Error.reducer';
import { translate } from '../Services/translation';

const defaultState = {
    message: translate('backinstock.add'),
    loading: false,
    errors: {},
}

export const inStock = (state = defaultState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PRODUCT_ADD_TO_BACK_IN_STOCK:
            return {
                ...state,
                ...payload,
            };
        default:
            return state;
    }
}
import { post } from '../Services/http';
import { catchError } from './Error.action';

export const QUICK_SEARCH_QUERY = "QUICK_SEARCH_QUERY";
export const QUICK_SEARCH_RECEIVE = "QUICK_SEARCH_RECEIVE";
export const QUICK_SEARCH_ERROR = "QUICK_SEARCH_ERROR";
export const QUICK_SEARCH_SHOW_FULL_FORM = 'QUICK_SEARCH_SHOW_FULL_FORM';
export const QUICK_SEARCH_SELECT_ITEM = "QUICK_SEARCH_SELECT_ITEM";

export const query = q => (dispatch, getState) => {
    dispatch({
        type: QUICK_SEARCH_QUERY,
        payload: {
            query: q,
        }
    });
    return post('/api/quickSearch', q)
        .then(response => response.json())
        .then(result => dispatch(receive(result)))
        .catch(ex => dispatch(catchError(ex, error => searchError(error))))
        ;
}

export const searchError = error => ({
    type: QUICK_SEARCH_ERROR,
    payload: {
        error,
    }
})

export const receive = result => ({
    type: QUICK_SEARCH_RECEIVE,
    payload: {
        result,
        showResult: result && result.length > 0
    }
})

export const toggleShowFullForm = () => (dispatch, getState) => {
    let body = document.getElementsByTagName('body')[0];
    if (!getState().quickSearch.showFullForm) {
        body.classList.add('no-scroll');
    } else {
        body.classList.remove('no-scroll');
    }
    dispatch(show(!getState().quickSearch.showFullForm));
}

const show = visible => ({
    type: QUICK_SEARCH_SHOW_FULL_FORM,
    payload: {
        showFullForm: visible,
    }
})

export const handleKeyDown = (event, opt) => (dispatch, getState) => {
    const { result, selectedItem } = getState().quickSearch;

    if (!result || !result.length) {
        return;
    }
    const max = result.length - 2, /* skip show all button in the end */
        clip = index => index < 0 ? max : index > max ? 0 : index;
    switch (event.keyCode) {
        case 38: 
            dispatch(selectItem(clip(selectedItem - 1)));
            break;
        case 40: 
            dispatch(selectItem(clip(selectedItem + 1)));
            break;
        case 13:
            location.href = opt.searchUrl;
            break;
        default: 
            break;
    }
}

const selectItem = selectedItem => ({
    type: QUICK_SEARCH_SELECT_ITEM,
    payload: {
        selectedItem,
    }
})
import { CHECKED_COOKIE, ACCEPT_COOKIES, SHOW_MORE } from '../Actions/CookiesCTA.action';

export const cookiesCTA = (state = { acceptedCookies: false, checkedForCookie: false, showMore: false }, action) => {
    switch (action.type) {
        case CHECKED_COOKIE:
            return {
                ...state,
                ...action.payload,
            };
        case ACCEPT_COOKIES:
            return {
                ...state,
                ...action.payload,
            };
        case SHOW_MORE:
            return {
                ...state,
                ...action.payload,
            };
        default:
            return state;
    }
}
import { get } from '../Services/http';
import { catchError } from './Error.action';

export const NAVIGATION_LOAD = 'NAVIGATION_LOAD';
export const NAVIGATION_LOAD_ERROR = 'NAVIGATION_LOAD_ERROR';
export const NAVIGATION_RECEIVE = 'NAVIGATION_RECEIVE';
export const NAVIGATION_SHOW = 'NAVIGATION_SHOW';
export const NAVIGATION_HIDE = 'NAVIGATION_HIDE';

export const load = () => (dispatch, getState) => {
    return get('/api/navigation')
        .then(response => response.json())
        .then(data => dispatch(receive(data)))
        .catch(ex => dispatch(catchError(ex, error => loadError(error))))
}

export const loadError = error => ({
    type: NAVIGATION_LOAD_ERROR,
    payload: {
        error,
    }
})

export const receive = data => ({
    type: NAVIGATION_RECEIVE,
    payload: {
        contentLinks : data.contentLinks,
    },
})

export const showMenu = () => ({
    type: NAVIGATION_SHOW,
    payload: {
        visible: true,
    },
})

export const hideMenu = () => ({
    type: NAVIGATION_HIDE,
    payload: {
        visible: false,
    },
})
import React from 'react';

const Notification = ({ text, top, left }) => (
    <div className="row">
        <div className="columns small-12">
            <span>{text}</span>
        </div>
    </div>
);

export default Notification;
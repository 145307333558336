import React from 'react';
import { translate } from '../Services/translation';

const MiniCart = ({ quantity, orderTotal, checkoutUrl, showInfo, toggle, orderRows, removeOrderRow, isBusinessCustomer }) => {
    if (isBusinessCustomer) {
        return (
            <div className="cart cart--mini">
                <a href="#" className="cart__link--block" onClick={() => toggle()}>
                    <span className="cart__title">{translate('minicart.checkout')}</span>
                    <i className="cart__icon">
                        <span className="cart__quantity">{quantity}</span>
                    </i>
                </a>
                <div className={`cart__info ${!showInfo ? 'cart__info--hidden' : ''}`}>
                    <span className="cart__close-button" onClick={() => toggle()}>
                    </span>
                    <p className="cart__info-row">{quantity} {translate('minicart.numberofproduct')}</p>
                    <p className="cart__info-row">
                        <b>{translate('minicart.total')}:</b>
                        <br />
                        {orderTotal}
                    </p>
                    <a href={checkoutUrl} className="cart__checkout-button button">{translate('minicart.checkout')}</a>
                </div>
            </div>
        )
    } else {
        return (
            <div className="cart cart--mini">
                <a href="#" className="cart__link--block" onClick={() => toggle()}>
                    <div className="cart__title">{translate('minicart.checkout')} <span className="cart__separator">/</span>{quantity}</div>
                    <i className="cart__icon">
                        <span className="cart__quantity">{quantity}</span>
                    </i>
                </a>
                <div className={`cart__info ${!showInfo ? 'cart__info--hidden' : ''}`}>
                    {/* <span className="cart__close-button" onClick={() => toggle()}>
                    </span> */}
                    <p className="cart__info-row">{quantity} {translate('minicart.numberofproduct')}</p>
                    {orderRows.slice(0).reverse().map((order, index) => {
                        if (index <= 2) {
                            return (
                                <div key={`${index}`} className="cart__info-row">
                                    <div className="row product-row">
                                        <div className="columns small-4">
                                            <img className="cart__item-image" src={order.image} />
                                        </div>
                                        <div className="columns small-7">
                                            <p>{order.name}</p>
                                            <p>{order.quantityString + ' st'}</p>
                                        </div>
                                        <div className="columns small-1">
                                            <a className="cart__inf cart__info--remove" onClick={() => removeOrderRow(order.rowSystemId)} title={translate('general.remove')}></a>
                                        </div>
                                    </div>
                                </div>
                            )
                        }
                    }
                    )}
                    <p className="cart__info-row"><b>{translate('minicart.total')}</b> {orderTotal}</p>
                    <a href={checkoutUrl} className="cart__checkout-button button">{translate('minicart.checkout')}</a>
                </div>
            </div>
        )
    }
}

export default MiniCart;
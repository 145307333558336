import React, { Component } from 'react';
import { connect } from 'react-redux';
import QuickSearch from '../Components/QuickSearch';
import { query, toggleShowFullForm, handleKeyDown } from '../Actions/QuickSearch.action';

class QuickSearchContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {...props};
        this.clickHandler = this.clickHandler.bind(this);
        this.headerScrollHandler = this.headerScrollHandler.bind(this);
        this.lastClickedNode = null;
        this.waiting = false;
        this.prevPos = 0;
        this.header = document.getElementById('header');
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.clickHandler);
        window.addEventListener('scroll', this.headerScrollHandler);
    }

    componentWillUpdate({ showFullForm } = props) {
        if (this.state.showFullForm !== showFullForm) {
            this.setState({...this.state, showFullForm});
        }
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.clickHandler);
        window.removeEventListener('scroll', this.headerScrollHandler);
    }

    clickHandler(event) {
        this.lastClickedNode = event.target;
    }

    onBlur() {
        const { showFullForm, toggleShowFullForm } = this.props;
        if (this.searchContainer && !this.searchContainer.contains(this.lastClickedNode)) {
            showFullForm && toggleShowFullForm();
        }
    }

    headerScrollHandler() {
        if (this.props.showFullForm) {
            return;
        }

        //if (this.waiting) {
        //    if ((window.pageYOffset | document.body.scrollTop) <= 25) {
        //        this.header.classList.remove('hidden');
        //    }
        //    return;
        //}
        //this.waiting = true;

        var scrollTop = window.pageYOffset | document.body.scrollTop;

        if (scrollTop > this.prev && scrollTop > 50 && !this.header.classList.contains('hidden')) {
            this.header.classList.add('hidden');
        } else if (scrollTop <= this.prev) {
            this.header.classList.remove('hidden');
        }

        this.prev = scrollTop;

        //setTimeout(function () {
        //    this.waiting = false;
        //}, 100);
    }

    render() {
        const { query } = this.props;
        const searchUrl = window.__litium.quickSearchUrl + (query.length > 0 ? `?q=${query}` : '');
        return (
            <div ref={(elem) => this.searchContainer = elem} >
                <QuickSearch {...{...this.props, searchUrl, onBlur: () => this.onBlur() }} />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        query: state.quickSearch.query,
        result: state.quickSearch.result,
        showResult: state.quickSearch.showResult,
        showFullForm: state.quickSearch.showFullForm,
        selectedItem: state.quickSearch.selectedItem,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        onSearch: (text) => dispatch(query(text)),
        toggleShowFullForm: () => dispatch(toggleShowFullForm()),
        onKeyDown: (event, opt) => dispatch(handleKeyDown(event, opt)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(QuickSearchContainer);